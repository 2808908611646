import * as React from "react"
import Layout from "../components/layout"

import "../styles/global.css"
import HeroSection from "../components/hero"
import TextSection from "../components/TextSection"
import MetricsSection from "../components/MetricsSection"
import DescriptionSection from "../components/DescriptionSection"
import ContactSection from "../components/ContactSection"

const Despre = () => (
  <Layout>
    <HeroSection />
    <TextSection />
    <MetricsSection />
    <DescriptionSection />
    <ContactSection />
  </Layout>
)

export default Despre
